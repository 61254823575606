function openPriceListInNewTab() {
  window.open(
    "https://docs.google.com/document/d/1uACG8R-66Xwu4XTVRZQdKUdL-e35M5Kj4ypHzLtMorE/export?format=pdf",
    "_blank",
  );
}

function listenToFormEvents(formEl) {
  const phoneNumberEl = formEl.querySelector("[name=phoneNumber]");
  const emptyNumberErrorMsgEl = formEl.querySelector("[role=alert]");

  phoneNumberEl.addEventListener("input", (e) => {
    const phoneNumber = e.target.value.replace(/[^\d\(\)\s-+]/g, "");
    e.target.value = phoneNumber;
    emptyNumberErrorMsgEl.classList.remove("mrkt-text-flame");
    phoneNumberEl.classList.remove("mrkt-border-flame");
  });

  formEl.addEventListener("submit", (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const phoneNumber = Object.fromEntries(
      formData.entries(),
    ).phoneNumber.trim();

    if (phoneNumber === "+375" || phoneNumber === "") {
      if (!emptyNumberErrorMsgEl.classList.contains("mrkt-text-flame")) {
        emptyNumberErrorMsgEl.classList.add("mrkt-text-flame");
      } else {
        phoneNumberEl.classList.add("mrkt-border-flame");
      }

      return;
    }

    fetch("https://hook.us1.make.com/dn2p575q2rsjiatghgq14qla3mjpcyxv", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phoneNumber }),
    });

    openPriceListInNewTab();
  });
}

document.addEventListener("DOMContentLoaded", function () {
  const getPricesFormEl = document.getElementById("get-prices-form");
  const getPricesFormMobileEl = document.getElementById(
    "get-prices-form-mobile",
  );

  listenToFormEvents(getPricesFormEl);
  listenToFormEvents(getPricesFormMobileEl);
});
